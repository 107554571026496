<template>
  <section class="result-positions">
    <div class="result-positions__header">
      <div class="result-positions__header__title">
        <h3>Gráficos de posicionamento</h3>
        <p>
          Analisamos as notas de todos os alunos que fizeram a avaliação.
          Use estes dados para comparar seu desempenho
          por disciplina em nível nacional.
        </p>
      </div>

      <div class="result-positions__legend">
        <p v-if="!isDigitalEnem">
          Para a leitura dos gráficos, use a legenda:
        </p>

        <ChartLegend :color="$tokens.color_primary">
          Seu posicionamento
        </ChartLegend>

        <ChartLegend :color="$tokens.color_ink_lighter">
          Demais alunos
        </ChartLegend>
      </div>
    </div>

    <div
      v-if="loading"
      class="result-positions__loading"
    >
      <Spinner />
    </div>

    <transition-group
      v-else
      appear
      :class="!hasError ? 'result-positions__grid' : ''"
      name="cards"
      tag="div"
    >
      <InfoCard
        v-if="hasError"
        key="error"
        class="score-card"
        error
        @refresh="reload()"
      />
      <Box
        v-for="histogram in categoriesHistogram"
        v-else
        :key="histogram.category"
        class="result-positions__card"
        padding="24px"
      >
        <h4>{{ histogram.category }}</h4>
        <VerticalBarChart
          bar-width="56px"
          :bars="getHistogramTracks(histogram)"
          gutter="24px"
          height="148px"
          :total="100"
          :x-label="chartConfig.xLabel"
          :y-label="chartConfig.yLabel"
        />
      </Box>
    </transition-group>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import InfoCard from '@/components/InfoCard'
import ChartLegend from '@/components/ChartLegend'
import VerticalBarChart from '@/components/VerticalBarChart'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'RankedCategoryCharts',

  components: {
    InfoCard,
    ChartLegend,
    VerticalBarChart,
  },

  mixins: [mediaQueries],

  props: {
    examId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      categoriesHistogram: [],
      hasError: false,
      loading: false,
    }
  },

  computed: {
    ...mapGetters([
      'assessment',
      'isDigitalEnem',
    ]),

    chartConfig() {
      if (this.isDigitalEnem) {
        return {
          xLabel: '',
          yLabel: '',
        }
      }

      return {
        xLabel: 'Nota Bruta',
        yLabel: 'Alunos',
      }
    },
  },

  created() {
    this.loadCategoriesCharts()
  },

  methods: {
    ...mapActions([
      'getCategoriesHistogram',
      'getExamTypeHistogram',
    ]),

    reload() {
      this.loadCategoriesCharts(true)
    },

    async loadCategoriesCharts(force = false) {
      this.hasError = false
      this.loading = true

      try {
        let params = null
        let action = null
        let data

        if (this.isDigitalEnem) {
          params = {
            assessmentId: this.assessment.id,
            force,
          }
          action = 'getExamTypeHistogram'
        } else {
          params = {
            examId: this.examId,
            force,
          }
          action = 'getCategoriesHistogram'
        }

        data = await this[action](params)

        if (this.isDigitalEnem) {
          data = data.map((categoryHistogram) => ({
            category: categoryHistogram.examType,
            chartBars: categoryHistogram.chartBars,
          }))
        }

        this.categoriesHistogram = data
      } catch (error) {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },

    getHistogramTracks(histogram) {
      return histogram.chartBars.map((track) => {
        const { lowerBound, upperBound } = track
        const lowerBoundLabel = lowerBound.toLocaleString('pt-br')
        const upperBoundLabel = upperBound.toLocaleString('pt-br')

        return {
          value: track.percentage,
          label: `${lowerBoundLabel} a ${upperBoundLabel}`,
          color: track.selected ? this.$tokens.color_primary : '',
        }
      })
    },
  },
}
</script>

<style lang="sass">

.result-positions
  &__header
    display: grid
    grid-template-columns: 1fr 0.4fr
    align-items: color-ink-light
    column-gap: 40px
    margin-bottom: 24px

    +mq(860px)
      grid-template-columns: 100%
      gap: 24px

    +mq-m
      padding: 0 24px

    +mq-s
      padding: 0 16px

    &__title

      p
        margin-top: 8px
        color: $color-ink-light

  &__legend
    box-sizing: border-box
    padding: 16px
    border-radius: 8px
    background: transparentize($color-ink-lighter, .8)

    & > p
      margin-bottom: 16px

    .sas-chart-legend:not(:last-child)
      margin-bottom: 8px

  &__card
    +mq-l
      padding: 16px !important
    +mq-m
      border-radius: 0 !important

  &__loading
    width: 100%
    +flex-center
    padding: 80px
    box-sizing: border-box

  &__grid
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 24px

    +mq-l
      gap: 16px

    +mq-m
      grid-template-columns: 100%

    .vertical-bar-chart

      &__bars
        +mq-l
          grid-template-columns: repeat(5, 48px) !important
          column-gap: 16px !important
          padding: 0 12px

        +mq(860px)
          column-gap: 12px !important

      &__x-label, &__y-label
        +mq-l
          font-size: 13px

      &__y-label
        +mq-l
          padding-left: 4px

        +mq-s
          display: none

      &__x-label
        +mq-l
          padding-top: 4px

        +mq-s
          display: none

    h4
      margin-bottom: 16px

</style>
