<template>
  <section
    v-if="isRankedAssessment || hasStudyGuide !== null"
    class="study-guide-banner"
  >
    <h3 class="study-guide-banner__title">
      {{ bannerTitle }}
    </h3>

    <div
      v-if="isRankedAssessment || hasStudyGuide"
      class="study-guide-banner__description"
    >
      <p>{{ description }}</p>
    </div>

    <div v-else>
      <h5 class="study-guide-banner__description">
        {{ descriptionSuccess }}
      </h5>

      <h5 class="study-guide-banner__description">
        Continue se esforçando!
      </h5>
    </div>

    <SasButton
      v-if="isRankedAssessment || hasStudyGuide"
      class="study-guide-banner__button"
      size="big"
      theme="secondary"
      @click="$emit('click')"
    >
      <SasIcon
        slot="left"
        type="check-square"
      />
      Acesse o Plano de Estudos
    </SasButton>

    <SasButton
      v-if="hasVideo"
      class="study-guide-banner__button"
      size="big"
      theme="float-dark"
      @click="$emit('open-video')"
    >
      <Icon
        slot="left"
        type="video"
      />
      Entenda como usar
    </SasButton>

    <img
      v-if=" isRankedAssessment || hasStudyGuide && !isSmallScreen"
      class="study-guide-banner__illustration"
      src="~@/assets/study-guide-banner-illustration.svg"
    >

    <img
      v-else-if="!hasStudyGuide"
      class="study-guide-banner__illustration"
      src="~@/assets/congratulations-banner-illustration.svg"
    >
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'StudyGuideBanner',
  props: {
    descriptionSuccess: {
      type: String,
      default:
        'Seu desempenho na prova foi excelente e você está pronto(a) para os desafios desta série.',
    },
    description: {
      type: String,
      default:
        'Veja suas habilidades a melhorar e receba uma lista com questões inéditas para praticar!',
    },
    hasVideo: Boolean,
  },

  data() {
    return {
      hasStudyGuide: null,
      loading: false,
    }
  },

  computed: {
    ...mapGetters(['isRankedAssessment']),

    bannerTitle() {
      return this.isRankedAssessment || this.hasStudyGuide
        ? 'Desenvolva habilidades com o Plano de Estudos'
        : 'Você está de parabéns! 👏'
    },

    isSmallScreen() {
      return this.$mq === 'x_small' || this.$mq === 'small'
    },
  },

  mounted() {
    if (!this.isRankedAssessment) {
      this.load()
    }
  },

  methods: {
    ...mapActions(['existsStudyGuide']),

    async load() {
      this.loading = true
      const { applicationId, examId } = this.$route.params
      this.hasStudyGuide = await this.existsStudyGuide({
        applicationId,
        examId,
      })
      this.loading = false
    },
  },
}
</script>

<style lang="sass">
$linear-gradient: 78.74deg, #546ECA 0%, #5E70D9 50.02%, #4E89EF 100.19%

.study-guide-banner
  position: relative
  box-sizing: border-box
  padding: 32px 40px
  border-radius: 12px
  overflow: hidden
  color: white
  background: linear-gradient($linear-gradient)

  +mq-m
    padding: 24px 32px

  +mq-s
    padding: 16px 24px

  h5:nth-child(2)

    +mq-s
      margin-bottom: 56px

  &__title,
  &__description,
  &__button
    position: relative
    z-index: 1

  &__title

    +mq-s
      font-size: 20px

    +mq-xs
      font-size: 18px

  &__button
    margin-top: 32px

    & ~ &
      margin-left: 32px

  &__description
    margin-top: 16px
    opacity: .8
    max-width: 55%

    +mq-m
      max-width: 70%

    +mq-s
      font-weight: 400
      max-width: 100%

  &__illustration
    position: absolute
    bottom: 0
    right: 0
    z-index: 0
    width: 304px

    +mq-m
      width: 240px

    +mq-s
      width: 176px

    +mq-xs
      width: 152px

</style>
