var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"category-performance"},[_c('h3',[_vm._v("Disciplinas")]),_c('div',[_c('DataTable',{attrs:{"data":_vm.studentCategoryPerformance,"density":_vm.tableDensity,"error":_vm.hasError,"fields":_vm.subjectsFields,"freeze-first-column":_vm.mq_s,"loading":_vm.loadingSubjects},on:{"refresh":function($event){return _vm.reload()}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"category-name"},[_vm._v(" "+_vm._s(row.name)+" "),(
              !_vm.isHighSchool
                && _vm.categoriesNotPresentInResult.includes(
                  row.name.toLowerCase())
            )?[_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                ("" + (_vm.$tc('components.categoryPerformance.tooltip', row.name)))
              ),expression:"\n                `${$tc('components.categoryPerformance.tooltip', row.name)}`\n              "}],attrs:{"stroke":_vm.$tokens.color_danger,"type":"info"}})]:_vm._e()],2)]}},{key:"grade",fn:function(ref){
              var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatGrade(row.grade))+" ")]}},{key:"globalGrade",fn:function(ref){
              var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatGrade(row.globalGrade))+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }