<template>
  <section class="category-performance">
    <h3>Disciplinas</h3>
    <div>
      <DataTable
        :data="studentCategoryPerformance"
        :density="tableDensity"
        :error="hasError"
        :fields="subjectsFields"
        :freeze-first-column="mq_s"
        :loading="loadingSubjects"
        @refresh="reload()"
      >
        <template
          slot="name"
          slot-scope="{ row }"
        >
          <div class="category-name">
            {{ row.name }}

            <template
              v-if="
                !isHighSchool
                  && categoriesNotPresentInResult.includes(
                    row.name.toLowerCase())
              "
            >
              <Icon
                v-tooltip="
                  `${$tc('components.categoryPerformance.tooltip', row.name)}`
                "
                :stroke="$tokens.color_danger"
                type="info"
              />
            </template>
          </div>
        </template>

        <template
          slot="grade"
          slot-scope="{ row }"
        >
          {{ formatGrade(row.grade) }}
        </template>

        <template
          slot="globalGrade"
          slot-scope="{ row }"
        >
          {{ formatGrade(row.globalGrade) }}
        </template>
      </DataTable>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

import DataTable from '@/components/DataTable'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'RankedCategoryPerformance',

  components: {
    DataTable,
  },

  mixins: [mediaQueries],

  props: {
    isHighSchool: {
      type: Boolean,
      required: true,
    },

    assessmentId: {
      type: [Number, String],
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      categoriesPerformance: [],
      globalPerformance: [],
      loadingSubjects: false,
      hasError: false,
      subjectsFields: [
        {
          text: 'Disciplina',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Acertos',
          value: 'hitsCount',
          sortable: true,
        },
        {
          text: 'Nota',
          value: 'grade',
          sortable: true,
        },
        {
          text: 'Nacional',
          value: 'globalGrade',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    categoriesNotPresentInResult() {
      return ['filosofia', 'arte']
    },

    tableDensity() {
      if (this.mq_m) {
        return 'medium'
      } if (this.mq_s) {
        return 'small'
      }

      return 'large'
    },

    studentCategoryPerformance() {
      return this.categoriesPerformance.map((performance) => ({
        ...performance,
        globalGrade: this.globalPerformance.find(
          ({ name }) => name === performance.name,
        ).grade,
      }))
    },
  },

  created() {
    this.loadCategoriesPerformance()
  },

  methods: {
    ...mapActions([
      'getPerformanceByCategories',
      'getGlobalPerformanceByCategories',
    ]),

    reload() {
      this.loadCategoriesPerformance(true)
    },

    async loadCategoriesPerformance(force = false) {
      this.hasError = false
      this.loadingSubjects = true
      try {
        const { assessmentId, examId } = this
        const params = {
          assessmentId,
          examId,
          force,
        }
        const [performance, globalPerformance] = await Promise.all([
          this.getPerformanceByCategories(params),
          this.getGlobalPerformanceByCategories(params),
        ])
        this.categoriesPerformance = performance
        this.globalPerformance = globalPerformance
      } catch (error) {
        this.hasError = true
      } finally {
        this.loadingSubjects = false
      }
    },

    formatGrade(score) {
      const decimalModifier = 10

      // eslint-disable-next-line no-undefined
      return (decimalModifier * score).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
  },
}
</script>

<style lang="sass">

.category-performance

  +mq-s
    overflow-x: scroll

    .data-table td:first-child,
    .data-table th:first-child
        padding-left: 16px

    .data-table th
      padding: 8px 8px

    .data-table td
      padding: 16px 8px
      text-align: center

  h3
    margin-bottom: 24px

  .category-name
    display: flex
    align-items: center

    i
      margin-left: 8px
</style>
