<template>
  <div
    class="empty-state"
    :style="{ padding }"
  >
    <img
      v-if="image.url"
      class="empty-state__image"
      :src="image.url"
      :width="image.width"
    >

    <Icon
      v-if="icon.type"
      class="empty-state__icon"
      :size="icon.size ? icon.size : 32"
      :stroke="icon.stroke"
      :stroke-width="icon.strokeWidth"
      :type="icon.type"
    />

    <h4
      v-if="title"
      class="empty-state__title"
      v-html="title"
    />

    <p
      v-if="description"
      class="empty-state__description"
      v-html="description"
    />

    <div
      v-if="$slots['actions']"
      class="empty-state__actions"
    >
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    image: {
      type: Object,
      default: () => ({
        url: '',
        width: '',
      }),
    },

    icon: {
      type: Object,
      default: () => ({
        type: '',
        size: 32,
        stroke: '',
        strokeWidth: '',
      }),
    },

    title: {
      type: String,
    },

    description: {
      type: String,
    },

    padding: {
      type: String,
      default: '40px',
    },
  },
}
</script>

<style lang="sass">

.empty-state
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  box-sizing: border-box
  text-align: center

  &__image
    margin-bottom: 24px

  &__icon
    margin-bottom: 24px

  &__title
    color: $color-ink

  &__description
    margin-top: 4px
    color: $color-ink-light

  &__actions
    margin-top: 24px

</style>
