var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Box',{staticClass:"info-card",attrs:{"elevation":_vm.elevation,"padding":"0"}},[_c('div',{staticClass:"info-card__header",style:({
      padding: _vm.padding.header,
      justifyContent: _vm.justify.header,
    })},[_c('h3',{staticClass:"info-card__title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._t("header")],2),(_vm.error)?_c('EmptyState',{attrs:{"icon":{
      type: 'alert-triangle',
      stroke: _vm.$tokens.color_danger,
    },"padding":"16px 40px 40px 40px","title":"<h5>Ocorreu um erro ao carregar este conteúdo.</h5>"}},[_c('SasButton',{attrs:{"slot":"actions"},on:{"click":function($event){return _vm.$emit('refresh')}},slot:"actions"},[_c('Icon',{attrs:{"slot":"left","size":20,"type":"refresh-cw"},slot:"left"}),_vm._v(" Recarregar ")],1)],1):_c('div',{staticClass:"info-card__body",style:({
      padding: _vm.padding.body,
      justifyContent: _vm.justify.body,
    })},[_vm._t("default")],2),(_vm.hasFooter && !_vm.error)?_c('div',{staticClass:"info-card__footer",style:({
      padding: _vm.padding.footer,
      justifyContent: _vm.justify.footer,
    })},[_vm._t("footer")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }