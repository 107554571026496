<template>
  <Box
    class="info-card"
    :elevation="elevation"
    padding="0"
  >
    <div
      class="info-card__header"
      :style="{
        padding: padding.header,
        justifyContent: justify.header,
      }"
    >
      <h3
        class="info-card__title"
        v-html="title"
      />
      <slot name="header" />
    </div>

    <EmptyState
      v-if="error"
      :icon="{
        type: 'alert-triangle',
        stroke: $tokens.color_danger,
      }"
      padding="16px 40px 40px 40px"
      title="<h5>Ocorreu um erro ao carregar este conteúdo.</h5>"
    >
      <SasButton
        slot="actions"
        @click="$emit('refresh')"
      >
        <Icon
          slot="left"
          :size="20"
          type="refresh-cw"
        />
        Recarregar
      </SasButton>
    </EmptyState>

    <div
      v-else
      class="info-card__body"
      :style="{
        padding: padding.body,
        justifyContent: justify.body,
      }"
    >
      <slot />
    </div>

    <div
      v-if="hasFooter && !error"
      class="info-card__footer"
      :style="{
        padding: padding.footer,
        justifyContent: justify.footer,
      }"
    >
      <slot name="footer" />
    </div>
  </Box>
</template>

<script>
import EmptyState from '@/components/EmptyState'

export default {
  name: 'InfoCard',

  components: {
    EmptyState,
  },

  props: {
    title: {
      type: String,
    },

    padding: {
      type: Object,
      default: () => ({
        header: '',
        body: '',
        footer: '',
      }),
    },

    elevation: {
      type: [String, Number],
      default: 1,
    },

    justify: {
      type: [Object, String],
      default: () => ({
        header: '',
        body: '',
        footer: '',
      }),
    },

    error: Boolean,
  },

  computed: {
    hasFooter() {
      return !!this.$slots.footer
    },
  },
}
</script>

<style lang="sass">
$font-size-custom-text: 28px

.info-card
  display: grid
  grid-template-rows: repeat(3, max-content)
  align-content: space-between

  &__header,
  &__body,
  &__footer
    box-sizing: border-box

  &__header
    +flex-space-between
    color: $color-ink-light
    padding: 24px 32px

    +mq-l
      padding: 16px 24px

  &__title
    line-height: 1
    font-weight: $font-weight-semi-bold
    font-size: $font-size-heading-3

  &__body
    display: flex
    padding: 48px 32px
    justify-content: flex-start

    +mq-l
      padding: 24px

    +mq-s
      padding: 16px

  &__footer
    align-self: flex-end
    display: flex
    align-items: center
    justify-content: flex-start
    padding: 16px 32px

    +mq-l
      padding: 12px 24px

    &:not(:empty)
      border-top: 1px solid transparentize($color-ink-lighter, .7)

    .info-2 ~ .info-2
      margin-left: 40px

  .info-card__body .info__content
    .info__text
      label
        font-size: $size-xl

  .info-card__footer .info__content
    .info__label
      font-size: $font-size-s
    .info__text
      font-size: $font-size-custom-text

</style>
