<template>
  <Box
    :background="background"
    :border-radius="borderRadius"
    class="data-table-container"
    :elevation="elevation"
    padding="0"
  >
    <transition
      mode="out-in"
      name="fade"
    >
      <EmptyState
        v-if="error && !loading"
        :icon="{
          type: 'alert-triangle',
          stroke: $tokens.color_danger,
        }"
        padding="80px"
        title="<h5>Ocorreu um erro ao carregar este conteúdo.</h5>"
      >
        <SasButton
          slot="actions"
          @click="$emit('refresh')"
        >
          <Icon
            slot="left"
            :size="20"
            type="refresh-cw"
          />
          Recarregar
        </SasButton>
      </EmptyState>

      <table
        v-if="!loading && !error"
        :class="[
          'data-table',
          {
            '--striped': striped,
            '--vertical-lines': verticalLines,
            '--freeze-first-column': freezeFirstColumn,
            '--selectable': selectable,
          },
          `--density-${density}`,
        ]"
        :style="{
          textAlign,
          borderRadius,
        }"
      >
        <thead class="data-table__header">
          <tr>
            <!-- Checkbox -->
            <th
              v-if="selectable"
              class="data-table__header__checkbox"
            >
              <input
                :checked="allSelected"
                type="checkbox"
                @click="$emit('select-all')"
              >
            </th>

            <th
              v-for="(field, index) in fields"
              :key="index"
              ref="tableColumn"
              v-tooltip="field.tooltip ? field.tooltip : ''"
              :class="{ '--sortable': field.sortable }"
              :style="{ minWidth: field.width ? field.width : '' }"
              @click="field.sortable ? updateSort(field.value) : ''"
            >
              <div class="data-table__header__field">
                <small
                  :style="{ textAlign }"
                  v-html="field.text"
                />
                <Icon
                  class="data-table__header__field__icon"
                  size="16"
                  stroke-width="3"
                  :style="{
                    visibility:
                      field.value === sortField
                      && field.sortable ? 'visible' : 'hidden',
                  }"
                  :type="sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'"
                />
              </div>
            </th>

            <th
              v-if="$scopedSlots['actions'] || $slots['actions']"
              class="data-table__actions"
            />
          </tr>
        </thead>

        <tbody class="data-table__body">
          <tr
            v-for="(row, index) in sortedData"
            :key="index"
            :class="{ '--active': activeRow === row }"
            :style="{ cursor: rowCursor }"
            :tabindex="index"
            @click="$emit('click-row', row)"
            @keydown.enter="$emit('click-row', row)"
          >
            <!-- Checkbox -->
            <td
              v-if="selectable"
              class="data-table__body__checkbox"
            >
              <input
                :checked="selectedRows.includes(row)"
                type="checkbox"
                @click.stop="$emit('select-row', row)"
              >
            </td>

            <!-- Data -->
            <td
              v-for="(field, index) in fields"
              :key="index"
            >
              <template v-if="!$scopedSlots[field.value]">
                {{ row[field.value] }}
              </template>

              <!-- Custom Cell -->
              <slot
                v-else
                :field="row[field]"
                :name="field.value"
                :row="row"
              />
            </td>

            <!-- Actions -->
            <td
              v-if="$scopedSlots['actions'] || $slots['actions']"
              class="data-table__actions"
            >
              <slot
                name="actions"
                :row="row"
              />
            </td>
          </tr>

          <tr
            v-if="footer.length > 0"
            class="data-table__footer"
          >
            <td
              v-for="(field, index) in footer"
              :key="index"
            >
              <span>{{ field }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Loading Table Skeleton  -->
      <table
        v-else
        class="data-table --loading"
        :class="`--density-${density}`"
      >
        <thead class="data-table__header">
          <tr class="data-table__header__full">
            <th
              v-for="c in skeletonColumns"
              :key="c"
            >
              <SkeletonLoader width="35%" />
            </th>
          </tr>
        </thead>

        <tbody class="data-table__body">
          <tr
            v-for="r in skeletonRows"
            :key="r"
          >
            <td
              v-for="c in skeletonColumns"
              :key="c"
              :style="{ paddingRight: c === 1 ? '64px' : '32px' }"
            >
              <SkeletonLoader :width="c === 1 ? '100%' : '35%'" />
            </td>
          </tr>
        </tbody>
      </table>
    </transition>
  </Box>
</template>

<script>
import EmptyState from '@/components/EmptyState'

export default {
  name: 'DataTable',

  components: {
    EmptyState,
  },

  props: {
    loading: Boolean,

    data: {
      type: Array,
      default: () => [],
    },

    fields: {
      type: Array,
      default: () => [],
    },

    error: Boolean,

    footer: {
      type: Array,
      default: () => [],
    },

    defaultSort: {
      type: Object,
      default: () => ({
        field: '',
        direction: 'asc',
      }),
    },

    striped: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },

    verticalLines: Boolean,

    textAlign: {
      type: String,
      default: 'left',
    },

    skeletonColumns: {
      type: Number,
      default: 4,
    },

    skeletonRows: {
      type: Number,
      default: 6,
    },

    freezeFirstColumn: Boolean,

    density: {
      type: String,
      default: 'large',
    },

    selectable: Boolean,

    allSelected: Boolean,

    background: {
      type: String,
      default: 'white',
    },

    activeRow: {
      type: Object,
      default: () => ({ id: 0 }),
    },

    rowCursor: {
      type: String,
      default: 'default',
    },

    selectedRows: {
      type: [Array, Object],
      default: () => [],
    },

    borderRadius: {
      type: String,
      default: '8px',
    },

    elevation: {
      type: [String, Number],
      default: 1,
    },

    dontSortOnInit: Boolean,
  },

  data() {
    return {
      sortField: this.defaultSort.value || this.fields[0].value,
      sortDirection: this.defaultSort.direction,
      alreadySorted: false,
    }
  },

  computed: {
    sortedData() {
      if (!this.alreadySorted && this.dontSortOnInit) {
        return this.data
      }

      return [...this.data].sort((a, b) => {
        let modifier = 1

        if (this.sortDirection === 'desc') {
          modifier = -1
        }
        if (a[this.sortField] < b[this.sortField]) {
          return -1 * modifier
        }
        if (a[this.sortField] > b[this.sortField]) {
          return 1 * modifier
        }

        return 0
      })
    },
  },

  methods: {
    updateSort(field) {
      this.alreadySorted = true
      this.sortField = field
      if (field === this.sortField) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      }
    },
  },
}
</script>

<style lang="sass">

.data-table
  width: 100%
  border-collapse: separate
  border-spacing: 0
  position: relative
  padding-bottom: 5px
  transition: all 200ms ease-in-out
  box-sizing: border-box

  // ---------------------------------------------
  // Densities
  // ---------------------------------------------
  $size-x-large: 48px
  $size-large: 32px
  $size-medium: 24px
  $size-small: 16px
  $size-x-small: 8px
  $size-xx-small: 4px

  &.--density-x-large
    td
      padding: $size-large $size-x-large
      font-size: 16px
    th
      padding: $size-x-small $size-x-large

  &.--density-large
    td
      padding: $size-medium $size-large
      font-size: 16px
    th
      padding: $size-x-small $size-large

  &.--density-medium
    td
      padding: $size-small $size-medium
    th
      padding: $size-x-small $size-medium

  &.--density-small
    td, th
      padding: $size-x-small $size-small

  &.--density-x-small
    td, th
      padding: $size-x-small $size-xx-small

  // --------------------------------------------------
  // Loading State
  // --------------------------------------------------
  &.--loading
    pointer-events: none

  // --------------------------------------------------
  // Vertical Lines
  // --------------------------------------------------
  &.--vertical-lines
    td:not(:last-child),
    th:not(:last-child)
      border-right: 1px solid transparentize($color-ink-lighter, 0.5)

  // --------------------------------------------------
  // Freeze First Column
  // --------------------------------------------------
  &.--freeze-first-column
    td:first-child,
    th:first-child
      left: 0
      position: sticky

      &::after
        content: ""
        position: absolute
        top: 0
        right: 0
        height: 106%
        width: 7px
        box-shadow: 3px 0 6px -3px rgba(0,0,0,.25)

    td:first-child
      z-index: 1

    th:first-child
      z-index: 30

    td:nth-child(2)
      margin-left: 8px

  // --------------------------------------------------
  // Footer Row
  // --------------------------------------------------
  &__footer
    td
      background: tint-color($color-ink, 85%) !important
      border-top: 1px solid transparentize($color-ink-lighter, 0.4)
      vertical-align: middle
      color: $color-ink-light
      box-sizing: border-box
      box-shadow: none
      position: sticky
      bottom: 0

      span
        font-size: 13px
        font-weight: 500

      &:first-child span
        width: 100%
        font-weight: 600
        display: block
        text-align: right !important

  // --------------------------------------------------
  // Header
  // --------------------------------------------------
  &__header
    text-align: left

    &__full
      width: 100%

    &__field
      display: flex
      align-items: center
      justify-content: flex-start

      &__icon
        margin-left: 4px
        opacity: .75
        flex-shrink: 0

    th
      background: tint-color($color-ink, 85%)
      position: sticky
      z-index: 8
      top: 0
      padding-right: 4px !important
      border-bottom: 1px solid transparentize($color-ink-lighter, 0.5)
      font-size: 13px
      vertical-align: middle
      color: $color-ink-light
      box-sizing: border-box

      small
        font-weight: 600
        user-select: none
        font-size: $font-size-s

      &.--sortable
        cursor: pointer

        &:hover
          background: tint-color($color-ink, 75%)
          color: $color-ink
          transition: all 200ms ease-in-out

      &:first-child
        text-align: left !important

  // --------------------------------------------------
  // Actions
  // --------------------------------------------------
  &__actions
    padding-left: 0 !important
    padding-right: 8px

  &.--selectable
    td:nth-child(2)
      font-weight: 600

  // --------------------------------------------------
  // Row
  // --------------------------------------------------
  tr
    transition: all 200ms ease-in-out

    &:focus
      outline: 0

    &:hover td
      background-color: tint-color($color-ink-lightest, 70%)

    &:last-child td
      border-bottom: none

    // Cell
    td
      border-bottom: 1px solid transparentize($color-ink-lighter, 0.6)
      box-sizing: border-box
      background: white
      font-size: 14px

      &:first-child
        font-weight: 600
        text-align: left !important

    // Active
    $row-active-border: 2px solid $color-primary

    &.--active
      td
        background-color: tint-color($color-primary, 90%) !important
        border-bottom: $row-active-border
        border-top: $row-active-border

        &:first-child
          border-left: $row-active-border

        &:last-child
          border-right: $row-active-border

      &:hover td
        background-color: tint-color($color-primary, 80%) !important

  // --------------------------------------------------
  // Striped Row
  // --------------------------------------------------
  &.--striped tr:nth-child(even)
    td
      background: tint-color($color-ink-lightest, 85%)

    &:hover td
      background-color: tint-color($color-ink-lightest, 70%)

</style>
